import React, { useState, useEffect } from 'react';
import { notification } from '../../component/hocs/notification';
import moment from 'moment';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';

import axios from 'axios';
import { mainUrl } from '../../MainUrl';
import DataTable from 'react-data-table-component';

import {
    Row, Col, Card, CardHeader, CardBody, Button, Label, Input, Modal, ModalBody, ModalHeader, Form, CardFooter
} from 'reactstrap';

import { FaRegEdit } from 'react-icons/fa';
import { Navigate } from 'react-router-dom';

const DailyDispatchMilk = () => {
    const [loading, setLoading] = useState(false);

    const [submitList, setSubmitList] = useState('');

    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [dispatchedList, setDispatchedList] = useState('');

    const [totalMilk, setTotalMilk] = useState(0);

    const [editModal, setEditModal] = useState(false);
    const [currRow, setCurrRow] = useState(false);
    const [buyerOpt, setBuyerOpt] = useState('');
    const [buyer, setBuyer] = useState('');

    useEffect(() => {
        get_buyer_list();
    }, []);

    const get_buyer_list = () => {

        axios.post(mainUrl + 'mis/getBuyerList')
            .then(function (response) {
                if (response.data.status === 1) {

                    let result = response.data.data;

                    let list = result.map((row, index) => {
                        return <option key={index} value={row.buyer_code}> {row.org_name} </option>
                    })

                    setBuyerOpt(list);

                }
            })
            .catch(error => {
                //setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_daily_dispatched_list = () => {
        setLoading(true);

        let postData = { "to_date": toDate, }

        axios.post(mainUrl + 'mis/dispatchedMilkList', postData)
            .then(function (response) {
                if (response.data.status === 1) {
                    setSubmitList(response.data.data);
                    let totMilk = 0;
                    let totPrice = 0;
                    let list = response.data.data.map((row, index) => {
                        totMilk = Number(totMilk) + Number(row.qty);
                        totPrice = Number(totPrice) + (Number(row.rate) * Number(row.qty));
                        return {
                            sl: index + 1,
                            fpo_name: row.fpo_name,
                            farmer_name: row.farmer_name,
                            qty: <span>{row.qty} ltrs</span>,
                            fat: row.fat,
                            type: row.milk_type,
                            rate: <span>&#8377; {Number(row.rate).toFixed(2)}</span>,
                            total: <span>&#8377; {Number(Number(row.rate) * Number(row.qty)).toFixed(2)}</span>,
                            can_no: row.can_no,
                            buyer_name: row.buyer_name,
                            action: <span>
                                <FaRegEdit className="cursor" size={20} color="secondary" onClick={() => { setCurrRow(row); setEditModal(true); }} />
                            </span>
                        }
                    })
                    //
                    let temp = {
                        sl: '',
                        fpo_name: <b>Total</b>,
                        farmer_name: '',
                        qty: <b>{totMilk} ltrs</b>,
                        fat: '',
                        type: '',
                        rate: '',
                        total: <b>&#8377; {Number(totPrice).toFixed(2)}</b>,
                        can_no: '',
                        buyer_name: '',
                        action:''
                    }
                    list.push(temp);
                    //
                    setDispatchedList(list);
                    setTotalMilk(totMilk);
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });

    }

    const change_buyer_handler = (e) => {
        e.preventDefault();
        let postData = { "buyer_code": buyer, "daily_production_id": currRow.daily_production_id };
        axios.post(mainUrl + 'mis/changeBuyer', postData)
            .then(function (response) {
                if (response.data.status === 1) {
                    let notify = notification({ message: response.data.message, type: 'success' });
                    notify();
                    get_daily_dispatched_list();
                    setEditModal(false);
                }
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });

    }

    const confirm_transaction_handler = () => {
        let valid = true;
        submitList.map(row => {
            if (row.buyer_code === 0 || row.buyer_code === null || row.buyer_code === undefined) {
                valid = false;
                let notify = notification({ message: "Add Buyer in all the transaction.", type: 'warn' });
                notify();
            }
        });

        if (valid) {
            let postData = { "dispatched_list": submitList };
            axios.post(mainUrl + 'mis/confirmTransaction', postData)
                .then(function (response) {
                    if (response.data.status === 1) {
                        let notify = notification({ message: response.data.message, type: 'success' });
                        notify();
                        Navigate('./daily-milk-transaction');
                    }
                })
                .catch(error => {
                    setLoading(false);
                    //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                    //notify();
                });
        }
    }

    return (
        <div>
            <Header />
            <Page>
                <Row>
                    <Col xs={12}>
                        <h5 className='text-primary'>Daily Dispatched Milk</h5>

                        <Card className='mb-3' color="primary" outline>
                            <CardBody>
                                <Row>
                                    <Col md={3}>
                                        <Label for="shareholder"> Date </Label>
                                        <Input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} required />
                                    </Col>
                                    <Col md={3}>
                                        <Button className='btn-margin' color='primary' size="sm" onClick={get_daily_dispatched_list}> Submit </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card color="primary" outline>
                            <CardHeader>Daily Dispatched Milk List</CardHeader>
                            <CardBody>
                                <DataTable
                                    title=""
                                    columns={[
                                        {
                                            name: '#',
                                            selector: row => row.sl,
                                            sortable: true,
                                            width: "80px"
                                        },
                                        {
                                            name: 'FPO Name',
                                            selector: row => row.fpo_name,
                                            sortable: false,
                                            wrap: true,
                                        },
                                        {
                                            name: 'Farmer Name',
                                            selector: row => row.farmer_name,
                                            sortable: false,
                                            wrap: true,
                                        },
                                        {
                                            name: 'Quantity',
                                            selector: row => row.qty,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Fat',
                                            selector: row => row.fat,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Milk Type',
                                            selector: row => row.type,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Rate/Kg',
                                            selector: row => row.rate,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Total',
                                            selector: row => row.total,
                                            sortable: false,
                                        },
                                        {
                                            name: 'CAN No.',
                                            selector: row => row.can_no,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Buyer Name',
                                            selector: row => row.buyer_name,
                                            sortable: false,
                                            wrap: true,
                                        },
                                        {
                                            name: 'Action',
                                            selector: row => row.action,
                                            sortable: false,
                                        },
                                    ]}
                                    data={dispatchedList}
                                    striped
                                    highlightOnHover
                                    progressPending={loading}

                                />
                            </CardBody>

                            <CardFooter className='text-end'>
                                <Button color='primary' size="sm" onClick={confirm_transaction_handler}> Confirm Transaction </Button>
                            </CardFooter>
                        </Card>
                    </Col>

                </Row>
            </Page>
            <Footer />


            {/* ---------- Edit Modal Start --------- */}
            <Modal
                centered
                scrollable
                size="md"
                isOpen={editModal}
                toggle={() => setEditModal(!editModal)}
            >
                <ModalHeader toggle={() => setEditModal(!editModal)}>
                    Edit
                </ModalHeader>
                <ModalBody className='bg-light'>
                    <Form onSubmit={change_buyer_handler}>
                        <Row>
                            <Col xs={12} className='mb-2'>
                                <label>Select Buyer</label>
                                <Input type="select" value={buyer} onChange={(e) => setBuyer(e.target.value)}>
                                    <option value="">-- Select Buyer --</option>
                                    {buyerOpt}
                                </Input>
                            </Col>
                            <Col xs={12} className='mb-2 text-end'>
                                <Button color='primary' size="sm"> Update </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
            {/* ---------- Edit Modal End --------- */}
        </div>
    )
}

export default DailyDispatchMilk;